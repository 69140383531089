import { defineStore } from 'pinia';

import useNotificationStore from './notification';

export const useStore = defineStore('main', {
	state: () => ({
		user: {
			id: null,
			name: '',
			email: '',
			permissions: [],
			line_provider_id: null,
		},
		isLogin: false,
		isLoading: false,
		isModal: {
			logout: false,
		},
	}),

	getters: {},

	actions: {
		setLoading(q) {
			this.isLoading = q;
		},

		setUser(q) {
			this.user.id = q.id;
			this.user.email = q.email;
			this.user.name = q.name;
			this.user.photo = q.photo;
			this.user.line_provider_id = q.line_provider_id;

			// in the future, permissions
			// try {
			// 	this.user.permissions = JSON.parse(q.permissions);
			// } catch (e) {
			// 	this.user.permissions = [];
			// }

			this.isLogin = true;
			localStorage.setItem('isLogin', this.isLogin);

			window.isLogin = true;
			window.user = this.user;

			// get notification
			const temp = useNotificationStore();

			// none official notification
			temp.getItems({ is_official: false, _pageSize: 5 }, true);

			// official notification, in the future
			// temp.getItems(
			// 	{
			// 		is_official: true,
			// 		_pageSize: 5,
			// 	},
			// 	true
			// );

			if (q.line_provider_id == 1) {
				alert('請前往新的line登入');
				document.location = '/login';
			}
		},

		logout() {
			this.user.id = null;
			this.user.email = '';
			this.user.name = '';
			this.user.permissions = [];
			this.isLogin = false;

			window.isLogin = false;
			window.user = {
				id: null,
				name: '',
				email: '',
				permissions: [],
			};

			localStorage.removeItem('token');
			localStorage.removeItem('isLogin');
		},
	},
});
