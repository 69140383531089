import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import { useStore } from '../store/main';

const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
		meta: {
			indexTab: 1,
		},
	},

	// new line login
	{
		path: '/login',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
	},

	// for transfer acocunt
	{
		path: '/login/transfer',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/transfer.vue'),
	},

	// merge  from old acocunt
	{
		path: '/login/merge',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/merge.vue'),
	},

	// old line login
	{
		path: '/login/old',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/old.vue'),
	},

	{
		path: '/login/email',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/email.vue'),
	},

	{
		path: '/login/mergeComplete',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/mergeComplete.vue'),
	},

	{
		path: '/login/alert',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/alert.vue'),
	},

	{
		path: '/explore',
		component: () => import(/* webpackChunkName: "explore" */ '../views/explore/index.vue'),
		meta: {
			indexTab: 2,
		},
	},

	{
		// old
		// path: '/article/item/:id(\\d+)/:name?',
		// new
		path: '/article/:id(\\d+)/:name?',
		component: () => import(/* webpackChunkName: "articleItem" */ '../views/article/item.vue'),
	},

	// old
	{
		path: '/article/list',
		component: () => import(/* webpackChunkName: "articleList" */ '../views/article/list.vue'),
	},

	// new
	// 官方標籤
	{
		// path: '/official_tag',
		path: '/official_tag/:id(\\d+)',
		component: () => import(/* webpackChunkName: "articleList" */ '../views/article/list.vue'),
	},
	// 自訂標籤
	{
		// path: '/tag',
		path: '/tag/:id(\\d+)',
		component: () => import(/* webpackChunkName: "articleList" */ '../views/article/list.vue'),
	},
	// 主題策展
	{
		// path: '/topic',
		path: '/topic/:id(\\d+)',
		component: () => import(/* webpackChunkName: "articleList" */ '../views/article/list.vue'),
	},

	{
		path: '/article/new',
		component: () => import(/* webpackChunkName: "articleUpdate" */ '../views/article/update.vue'),
		meta: {
			isLogin: true,
			isNewMode: true
		},
	},

	{
		// old
		// path: '/article/edit',
		// new
		path: '/article/edit/:id(\\d+)',
		component: () => import(/* webpackChunkName: "articleUpdate" */ '../views/article/update.vue'),
		meta: {
			isLogin: true,
			isEditMode: true
		},
	},

	// - 文章：/search/contents?keyword=[keyword]
	// - 創作者：/search/users?keyword=[keyword]
	{
		// old
		// path: '/search/list',
		// new
		path: '/search/contents',
		// path: '/search/contents/:keyword',
		component: () => import(/* webpackChunkName: "searchList" */ '../views/search/list.vue'),
	},

	{
		path: '/search/users',
		// path: '/search/user/:keyword',
		component: () => import(/* webpackChunkName: "searchList" */ '../views/search/list.vue'),
	},

	{
		path: '/user/list',
		component: () => import(/* webpackChunkName: "userList" */ '../views/user/list.vue'),
	},

	{
		// old
		// path: '/notification/list',
		path: '/notify',
		component: () => import(/* webpackChunkName: "notificationList" */ '../views/notification/list.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		// old
		// path: '/user/item/:id(\\d+)/:name?',
		// new
		path: '/user/:id(\\d+)/:name?',
		component: () => import(/* webpackChunkName: "userItem" */ '../views/user/item.vue'),
	},

	{
		path: '/version',
		component: () => import(/* webpackChunkName: "version" */ '../views/version.vue'),
	},

	/*
	..######..########.##.......########
	.##....##.##.......##.......##......
	.##.......##.......##.......##......
	..######..######...##.......######..
	.......##.##.......##.......##......
	.##....##.##.......##.......##......
	..######..########.########.##......
	*/

	{
		// old
		// path: '/self/article',
		// new
		path: '/my/contents',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/article.vue'),
		meta: {
			isLogin: true,
		},
	},

	// {
	// 	path: '/self/analytics',
	// 	path: '/self/fans',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/analytics.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },

	// {
	// 	path: '/self/block',
	// 	path: '/self/blocked',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/block.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },
	{
		// old
		// path: '/self/collect',
		// new
		path: '/my/collects',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/collect.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		// old
		// path: '/self/me',
		// new
		path: '/my/info',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/me.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		// old
		// path: '/self/tag',
		// new
		path: '/my/tags',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/tag.vue'),
		meta: {
			isLogin: true,
		},
	},

	// {
	// 	path: '/self/milestone',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/milestone.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },

	{
		// old
		// path: '/self/notification',
		// new
		path: '/my/notify-settings',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/notification.vue'),
		meta: {
			isLogin: true,
		},
	},

	/*
	..#######..########.##.....##.########.########.
	.##.....##....##....##.....##.##.......##.....##
	.##.....##....##....##.....##.##.......##.....##
	.##.....##....##....#########.######...########.
	.##.....##....##....##.....##.##.......##...##..
	.##.....##....##....##.....##.##.......##....##.
	..#######.....##....##.....##.########.##.....##
	*/
	{
		path: '/setToken',
		component: () => import(/* webpackChunkName: "site" */ '../views/setToken.vue'),
	},
	// for old login
	{
		path: '/setTokenOld',
		component: () => import(/* webpackChunkName: "site" */ '../views/setTokenOld.vue'),
	},

	// {
	// 	path: '/testLogin',
	// 	component: () => import(/* webpackChunkName: "test" */ '../views/testLogin.vue'),
	// },

	/*
	.##..........#####...##.......
	.##....##...##...##..##....##.
	.##....##..##.....##.##....##.
	.##....##..##.....##.##....##.
	.#########.##.....##.#########
	.......##...##...##........##.
	.......##....#####.........##.
	*/
	{
		path: '/:pathMatch(.*)*',
		component: () => import(/* webpackChunkName: "site" */ '../views/notFound.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 };
	},
});

async function checkUser() {
	let urlRedirect = null;
	if (!window.isStatusChecked) {
		const token = localStorage.getItem('token');
		if (token) {
			const config = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			try {
				let response = await axios.post(process.env.API_URL + '/v1/login/token', {}, config);

				const store = useStore();

				if (response.data.user.line_provider_id == 1) {
					// alert('請前往新的line登入');
					localStorage.setItem('tokenOld', token);
					localStorage.removeItem('token');
					urlRedirect = '/login/alert';
					// alert(urlRedirect);
				} else {
					store.setUser({
						id: response.data.user.id,
						name: response.data.user.name,
						email: response.data.user.email,
						photo: response.data.user.photo,
						line_provider_id: response.data.user.line_provider_id,
					});
				}
			} catch (e) {
				// remove token
				localStorage.removeItem('token');
			}
		}
	}
	window.isStatusChecked = true;
	return urlRedirect;
}

router.beforeEach(async (to, from, next) => {
	const urlRedirect = await checkUser();

	if (to.meta.isLogin) {
		if (window.isLogin) {
			if (urlRedirect) {
				next(urlRedirect);
			} else {
				next();
			}
		} else {
			next('/login');
		}
	} else {
		if (urlRedirect) {
			next(urlRedirect);
		} else {
			next();
		}
	}
});

export default router;
